import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAuthentication } from '../../../../common/hooks/authentication';
import { getPcsConfig } from '../../../../common/pricer-services';
import Crm from './components/Crm';
import NameAndBackButton from './components/NameAndBackButton';
import PcsSystem from './components/PcsSystem';
import Services from './components/Services';
import TenantInformation from './components/TenantInformation';
import TenantViewCardImageDetails from './components/TenantViewCardImageDetails';
import { StyledTenantDetailsDividerInnerSection } from './TenantDetailsSideArea.styles';
export function TenantDetailsSideArea(_a) {
    var tenant = _a.tenant;
    var has = useAuthentication().has;
    if (!tenant) {
        return _jsx(NameAndBackButton, { id: "0", name: "Unknown tenant" });
    }
    var pcs = getPcsConfig(tenant);
    var showCrm = has('pricer:staff') && tenant.crmSystems && tenant.crmSystems.length > 0;
    return (_jsxs(_Fragment, { children: [_jsx(NameAndBackButton, { id: tenant.id, name: tenant.name }), _jsx(TenantViewCardImageDetails, { tenantId: tenant.id, image: tenant.image }), _jsx(TenantInformation, { tenant: tenant }), pcs && (_jsxs("span", { children: [_jsx(StyledTenantDetailsDividerInnerSection, {}), _jsx(PcsSystem, { tenant: tenant, pcs: pcs })] })), _jsx(StyledTenantDetailsDividerInnerSection, {}), _jsx(Services, { services: tenant.services || [] }), showCrm && (_jsxs("span", { children: [_jsx(StyledTenantDetailsDividerInnerSection, {}), _jsx(Crm, { systems: tenant.crmSystems })] })), _jsx(StyledTenantDetailsDividerInnerSection, {})] }));
}
