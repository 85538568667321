var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, FormControl, TextField } from '@mui/material';
import React, { useState } from 'react';
export default function AddLinkList(_a) {
    var onAdd = _a.onAdd, current = _a.tenants, allTenants = _a.allTenants;
    var _b = useState(''), searchValue = _b[0], setSearchValue = _b[1];
    var currentIds = current.map(function (t) { return t.id; });
    var tenantList = allTenants
        .filter(function (t) { return !currentIds.includes(t.id); })
        .map(function (t) {
        return {
            id: t.id,
            name: t.name
        };
    });
    return (_jsx(FormControl, { margin: "dense", fullWidth: true, children: _jsx(Autocomplete, { id: "add-tenant", options: tenantList, inputValue: searchValue, getOptionLabel: function (option) {
                return option.name;
            }, isOptionEqualToValue: function (option, value) { return option.id === value.id; }, clearOnBlur: true, blurOnSelect: true, onChange: function (event, newValue) {
                if (newValue) {
                    onAdd(newValue.id);
                }
            }, onInputChange: function (event, value, reason) {
                if (reason === 'reset') {
                    setSearchValue('');
                }
                else {
                    setSearchValue(value);
                }
            }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: "Add link to current tenant" }))); } }) }));
}
