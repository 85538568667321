import { styled } from '@mui/material/styles';
export var StyledTenantArea = styled('div')(function () { return ({
    paddingRight: '20px',
    paddingBottom: '20px',
    width: 'calc(100% - 25px)',
    height: 'calc(100vh - 133px)',
    overflowY: 'scroll',
    overFlowX: 'hidden',
    marginRight: '20px',
    marginLeft: '20px',
    columnGap: '15px',
    display: 'grid',
    gridGap: '15px',
    gridTemplateRows: 'repeat(auto-fill,300px)',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))'
}); });
