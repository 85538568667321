var _a;
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { auth0UserApi } from '../api/auth0User';
import { profileApi } from '../api/profileApi';
import { tagApi } from '../api/tagApi';
import { tenantApi } from '../api/tenantApi';
import { userManagerApi } from '../api/userManager';
import { authSlice } from './authSlice';
import { errorSlice } from './errorSlice';
export var store = configureStore({
    reducer: (_a = {},
        _a[authSlice.name] = authSlice.reducer,
        _a[errorSlice.name] = errorSlice.reducer,
        _a[userManagerApi.reducerPath] = userManagerApi.reducer,
        _a[auth0UserApi.reducerPath] = auth0UserApi.reducer,
        _a[tenantApi.reducerPath] = tenantApi.reducer,
        _a[tagApi.reducerPath] = tagApi.reducer,
        _a[profileApi.reducerPath] = profileApi.reducer,
        _a),
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware()
            .concat(userManagerApi.middleware)
            .concat(auth0UserApi.middleware)
            .concat(tenantApi.middleware)
            .concat(profileApi.middleware)
            .concat(tagApi.middleware);
    }
});
export var useAppDispatch = function () { return useDispatch(); };
export var useAppSelector = useSelector;
