import '@fontsource/raleway'; // Defaults to weight 400.
import { grey, red, teal } from '@mui/material/colors';
export var Color;
(function (Color) {
    Color["Main"] = "#387A26";
    Color["Secondary"] = "#192A44";
    Color["White"] = "#FFFFFF";
    Color["Background"] = "#F8F8F8";
    Color["LightGray"] = "#B5B5B5";
    Color["Gray"] = "#D9D9D9";
    Color["Text"] = "#393939";
    Color["Error"] = "#ff0000";
    Color["OffWhite"] = "#F1F1F1";
    Color["Green"] = "#6FCA84";
    Color["PricerLogo"] = "#054816";
})(Color || (Color = {}));
var dark = false;
// A custom theme for this app
export var pricerTheme = {
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                contained: {
                    color: '#FFFFFF',
                    boxShadow: 'none'
                },
                outlined: {
                    color: '#387A26',
                    background: '#FFFFFF',
                    boxShadow: 'none',
                    '&:hover': {
                        //you want this to be the same as the backgroundColor above
                        background: '#FFFFFF',
                        border: 'none'
                    }
                },
                textPrimary: {},
                containedPrimary: {
                    color: '#FFFFFF'
                },
                root: {
                    textTransform: 'unset',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '14px',
                    lineHeight: '20px',
                    width: '144px',
                    height: '40px',
                    backgroundColor: '#387A26',
                    color: '#FFFFFF',
                    border: 0,
                    boxShadow: 'none',
                    '&:hover': {
                        //you want this to be the same as the backgroundColor above
                        backgroundColor: '#387A26',
                        border: 'none'
                    }
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: 500,
                    fontSize: 'smaller'
                },
                secondary: {
                    fontSize: 'smaller'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-input:-webkit-autofill': {
                        WebkitBoxShadow: "0 0 0 100px #e8f0fe inset",
                        WebkitTextFillColor: 'unset'
                    }
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    '&:focus': {
                        backgroundColor: 'white'
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                colorPrimary: {
                    border: 'solid 1px #3f64de',
                    backgroundColor: '#e8f2ff',
                    color: '#3f64de',
                    '&&:focus': {
                        backgroundColor: '#e8f2ff',
                        color: '#3f64de'
                    },
                    '&&:hover': {
                        backgroundColor: '#5d87bd',
                        color: 'white'
                    }
                },
                colorSecondary: {
                    border: 'solid 1px #c94343',
                    backgroundColor: '#ffefef',
                    color: Color.Error,
                    '&&:focus': {
                        backgroundColor: '#ffefef',
                        color: Color.Error
                    },
                    '&&:hover': {
                        backgroundColor: '#e65555',
                        color: 'white'
                    }
                }
            }
        }
    },
    palette: {
        background: {
            default: Color.Background
        },
        primary: {
            main: dark ? grey[500] : Color.Main
        },
        secondary: {
            main: dark ? teal[400] : Color.Secondary
        },
        error: {
            main: red.A400
        }
    },
    typography: {
        fontFamily: "\"Raleway\", \"Helvetica\", \"Arial\", \"sans-serif\"",
        h1: {
            fontSize: 48,
            fontWeight: 500,
            lineHeight: 1.17,
            color: 'rgba(0, 0, 0, 0.87)'
        },
        h2: {
            fontSize: 24,
            lineHeight: 1.33,
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        h3: {
            fontSize: 18,
            lineHeight: 1.33,
            fontWeight: 'bold',
            color: 'rgba(0, 0, 0, 0.87)'
        },
        subtitle1: {
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 'normal',
            lineHeight: 1.43,
            letterSpacing: '0.15px'
        },
        subtitle2: {
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 'bold',
            lineHeight: 1.43,
            letterSpacing: '0.15px'
        },
        overline: {
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.25)',
            fontWeight: 'normal',
            lineHeight: 1.43,
            letterSpacing: '0.15px'
        },
        caption: {
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: 2.58,
            color: 'rgba(0, 0, 0, 0.54)',
            letterSpacing: '1px'
        }
    }
};
