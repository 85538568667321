import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Typography from '@mui/material/Typography';
import React from 'react';
import ConnectTenant from '../../../common/assets/connect-tenant.svg';
export default function NoConnectedTenantsPage() {
    return (_jsxs("div", { style: {
            gridArea: '1 / 1 / last-line / last-line',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
        }, children: [_jsx("img", { style: { marginBottom: '20px' }, src: ConnectTenant, alt: "Connect Tenant" }), _jsx(Typography, { fontSize: '24px', children: "No connected tenants" }), _jsx(Typography, { fontSize: '12px', style: { marginBottom: '20px', textAlign: 'center' } })] }));
}
