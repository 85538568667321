import { ErrorLevel, errorSlice } from '../../redux/errorSlice';
import { useAppDispatch } from '../../redux/store';
export var useErrors = function () {
    var dispatch = useAppDispatch();
    var addAlert = function (message) {
        dispatch(errorSlice.actions.addError({
            message: message,
            errorLevel: ErrorLevel.ALERT
        }));
    };
    var clearError = function () {
        dispatch(errorSlice.actions.addError({
            message: '',
            errorLevel: ErrorLevel.NONE
        }));
    };
    return { addAlert: addAlert, clearError: clearError };
};
export var useMessageHandler = function () {
    var addError = function (msg) {
        errorSlice.actions.addError({
            message: msg,
            errorLevel: ErrorLevel.ALERT
        });
    };
    return { addError: addError };
};
