import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReceiptLong } from '@mui/icons-material';
import AbcIcon from '@mui/icons-material/Abc';
import CakeIcon from '@mui/icons-material/Cake';
import GavelIcon from '@mui/icons-material/Gavel';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { Box, LinearProgress } from '@mui/material';
import React from 'react';
import { useAuthentication } from '../../../../../common/hooks/authentication';
import MetaData from './atoms/MetaData';
import LookupUser from './LookupUser';
var CONTRACT_STATUSES = {
    REQUESTED: 'Requested',
    SIGNED_EULA: 'Signed EULA',
    SIGNED_SOW: 'Signed SOW',
    SIGNED_CONTRACT: 'Signed Contract',
    TERMINATED: 'Terminated'
};
var PROGRESS = {
    REQUESTED: 10,
    SIGNED_EULA: 20,
    SIGNED_SOW: 50,
    SIGNED_CONTRACT: 100
};
export default function TenantInformation(_a) {
    var tenant = _a.tenant;
    var has = useAuthentication().has;
    var progress = PROGRESS[tenant.contractStatus] || 0;
    var contractTitle = CONTRACT_STATUSES[tenant.contractStatus] || "".concat(tenant.contractStatus);
    return (_jsxs("div", { children: [_jsx(MetaData, { icon: _jsx(AbcIcon, {}), attribute: 'Tenant type:', value: tenant.type, dataTestId: 'tenant-details-type' }), _jsx(MetaData, { icon: _jsx(CakeIcon, {}), attribute: 'Created at:', value: tenant.createdAt ? tenant.createdAt.substring(0, 10) : '', dataTestId: 'tenant-details-created-at' }), tenant.createdBy && !has('pricer-profile:only') && (_jsx(LookupUser, { icon: _jsx(PersonPinIcon, {}), attribute: 'Created by:', value: tenant.createdBy, dataTestId: 'tenant-details-created-by' })), tenant.licenseType.toLowerCase() !== 'onprem' && (_jsx(MetaData, { icon: _jsx(ReceiptLong, {}), attribute: 'Contract status:', value: contractTitle, dataTestId: 'tenant-details-contract' })), progress !== 100 && (_jsx(Box, { paddingLeft: 3, paddingRight: 3, paddingBottom: 2, children: _jsx(LinearProgress, { variant: "determinate", value: progress }) })), _jsx(MetaData, { icon: _jsx(GavelIcon, {}), attribute: 'License type:', value: tenant.licenseType, dataTestId: 'tenant-details-license' })] }));
}
