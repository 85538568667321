export var PCS_REGIONS = {
    EU: 'Europe/Middle East/Africa',
    AMERICA: 'America',
    APAC: 'Asia Pacific',
    CANADA: 'Canada'
};
export var hasValue = function (value) {
    return value !== undefined && value !== null && value.length > 0;
};
export function getPricerService(_a, type) {
    var tenant = _a.tenant, services = _a.services;
    if (tenant) {
        return getPricerService({ services: tenant.services }, type);
    }
    if (!services) {
        return undefined;
    }
    return services.find(function (c) { return c.type.toLowerCase() === type.toLowerCase(); });
}
export function getPcsConfig(tenant) {
    var system = getPricerService({ tenant: tenant }, CENTRAL_MANAGER);
    if (!system) {
        return undefined;
    }
    return system.configuration;
}
export function mutatePricerService(services, type, configuration) {
    if (!services || services.length === 0) {
        return [{ type: type, configuration: configuration }];
    }
    var copy = services.filter(function (c) { return c.type.toLowerCase() !== type.toLowerCase(); });
    copy.push({ type: type.toLowerCase(), configuration: configuration });
    return copy;
}
export function removeInactiveServices(services) {
    if (!services || services.length === 0) {
        return [];
    }
    return services
        .filter(function (s) { return s.configuration; })
        .filter(function (s) { return Object.keys(s.configuration).length > 0; })
        .filter(function (s) {
        return Object.keys(s.configuration).find(function (k) { return s.configuration[k].length > 0; }) !== undefined;
    });
}
export function setPricerService(tenant, type, configuration) {
    if (!tenant.services || tenant.services.length === 0) {
        tenant.services = [{ type: type, configuration: configuration }];
        return;
    }
    var copy = tenant.services.filter(function (c) { return c.type.toLowerCase() !== type.toLowerCase(); });
    copy.push({ type: type.toLowerCase(), configuration: configuration });
    tenant.services = copy;
}
export function setPcsConfig(tenant, configuration) {
    setPricerService(tenant, CENTRAL_MANAGER, configuration);
}
export var CENTRAL_MANAGER = 'central manager';
export var METADATA_FIELDS = {
    'app manager': ['pcsId'],
    'central manager': ['requestedPcsId', 'pcsId', 'profile', 'region'],
    'data load': [],
    'plaza home': ['url'],
    monitor360: [],
    uptime: ['pcsId'],
    IAM: ['url'],
    Store: ['url'],
    link: ['url', 'name', 'description']
};
