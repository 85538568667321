import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
export var StyledTenantViewCard = styled('div')(function () { return ({
    cursor: 'pointer',
    border: '1px solid #CECFCE',
    backgroundColor: 'white',
    color: 'white',
    height: '300px',
    overflow: 'hidden',
    borderRadius: '10px'
}); });
export var StyledTenantNameText = styled(Typography)(function () { return ({
    color: '#1C1B1F',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    fontWeight: 'bold',
    marginTop: '19px',
    marginLeft: '20px'
}); });
