import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
export var AlertPopupButton = styled('div')(function () { return ({
    display: 'flex',
    marginRight: '48px',
    color: '#054816',
    '&:hover': {
        cursor: 'pointer'
    }
}); });
export var AlertPopupButtonTypography = styled(Typography)(function () { return ({
    color: '#054816',
    fontWeight: 'bold',
    size: '14px'
}); });
