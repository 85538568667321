var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { FaceOutlined } from '@mui/icons-material';
import { Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Globe from '../../common/assets/globe.png';
import Logo from '../../common/assets/logo.svg';
import { InputWrapper, LoginButton, LoginWrapper } from './LoginPage.styles';
export var LoginPage = function () {
    var _a = useAuth0(), loginWithRedirect = _a.loginWithRedirect, logout = _a.logout;
    var queryParams = useSearchParams()[0];
    var error = queryParams.get('error');
    var _b = useState('white'), bgColor = _b[0], setBgColor = _b[1];
    useEffect(function () {
        if (error) {
            setBgColor('#FFB3B3');
            setTimeout(function () {
                setBgColor('white');
            }, 3000);
            logout().then(function (r) { return console.log('Logged out', r); });
        }
    }, [error, setBgColor, logout]);
    var handleLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, loginWithRedirect()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(LoginWrapper, { children: [_jsx(Box, { sx: { mb: 1 }, children: _jsx("img", { src: Logo, alt: '' }) }), _jsx(InputWrapper, { children: _jsxs(Paper, { sx: {
                        padding: '40px',
                        minWidth: '420px',
                        width: '1px',
                        minHeight: '300px',
                        height: '1px',
                        alignSelf: 'center',
                        transition: 'all 1s ease-out',
                        backgroundColor: bgColor
                    }, children: [_jsx(FaceOutlined, { sx: { color: '#6FCA84', width: '40px', height: '40px' } }), _jsx(Typography, { sx: { fontSize: '40px', marginBottom: '40px' }, children: "To login page" }), _jsx(LoginButton, { variant: "contained", color: "primary", fullWidth: true, onClick: handleLogin, children: "Login" }), _jsx(Typography, { sx: {
                                fontSize: '16px',
                                textTransform: 'capitalize',
                                fontWeight: 'bold'
                            }, children: error ? error : 'You will be redirected when you press login' })] }) }), _jsx(Box, { component: "img", sx: {
                    width: '70vw',
                    position: 'fixed',
                    right: '-20%',
                    maxWidth: '1200px'
                }, src: Globe })] }));
};
