import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { useAuthentication } from '../../../../common/hooks/authentication';
import ContractButton from './components/ContractButton/ContractButton';
import { EditMenu } from './components/EditMenu';
import { RefreshButton } from './components/RefreshButton';
import { TenantFilterBarItems } from './TenantFilterBarItems';
import { TenantSearchBar } from './TenantSearchBar';
import { StyledTenantFilterBar } from './TenantSearchBar.styles';
export var TenantToolBar = function (props) {
    var has = useAuthentication().has;
    var hasChildren = props.tenant.links.length > 0;
    return (_jsxs(StyledTenantFilterBar, { children: [hasChildren && (_jsxs(_Fragment, { children: [_jsx(TenantSearchBar, { searchWord: props.searchWord, handleSearch: props.handleSearch, checked: props.checked, handleChecked: props.handleChecked }), _jsx(TenantFilterBarItems, { setFilterType: props.setFilterType })] })), _jsx("div", { style: {
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    paddingRight: '25px',
                    gap: '10px'
                } }), _jsxs(Stack, { direction: "row", spacing: 2, children: [_jsx(ContractButton, { tenant: props.tenant }), _jsx(RefreshButton, {}), has('tenant:super-admin') && _jsx(EditMenu, { id: props.tenant.id })] })] }));
};
