import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Loading from '../../components/molecules/Loading';
export default function TenantListLoading() {
    return (_jsxs("div", { style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
        }, children: ["\u00A0", _jsx(Loading, { title: "Switching tenant..." }), "\u00A0"] }));
}
