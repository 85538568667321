export var LICENSE_TYPES = {
    TEST: 'TEST',
    ACCEPTANCE: 'ACCEPTANCE',
    STAGING: 'STAGING',
    PRODUCTION: 'PRODUCTION',
    DEMO: 'DEMO',
    ONPREM: 'ONPREM'
};
export var DEFAULT_LICENSE_TYPE = 'TEST';
export var DEFAULT_ONPREM_LICENSE_TYPE = 'ONPREM';
export var TYPES = {
    INTERNAL: 'Internal',
    PARTNER: 'Partner',
    CUSTOMER: 'Customer'
};
export var DEFAULT_TYPE = 'INTERNAL';
export var DEFAULT_ONPREM_TYPE = 'CUSTOMER';
export var CONTRACT_STATUS = {
    INTERNAL: 'Internal',
    PENDING: 'Pending',
    REQUESTED: 'Requested',
    SIGNED_EULA: 'Signed EULA',
    SIGNED_SOW: 'Signed SOW',
    SIGNED_PO: 'Signed PO',
    SIGNED_CONTRACT: 'Signed Contract',
    SUSPENDED: 'Suspended',
    TERMINATED: 'Terminated'
};
export var DEFAULT_CONTRACT_STATUS = 'REQUESTED';
export var DEFAULT_ONPREM_CONTRACT_STATUS = 'SIGNED_CONTRACT';
