import { jsx as _jsx } from "react/jsx-runtime";
import { Avatar } from '@mui/material';
import { stringToColor } from '../../../../common/helpers';
export var AvatarIcon = function (_a) {
    var initials = _a.initials, _b = _a.size, size = _b === void 0 ? 32 : _b, _c = _a.fontSize, fontSize = _c === void 0 ? size / 2 : _c, _d = _a.color, color = _d === void 0 ? true : _d;
    return (_jsx(Avatar, { sx: {
            height: size,
            width: size,
            fontSize: fontSize,
            bgColor: color ? stringToColor(initials) : 'transparent'
        }, children: initials }));
};
