import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Button, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { StyledRemoveImageButton } from './UploadImageButton.styles';
export function UploadImageButton(_a) {
    var onRemoveImage = _a.onRemoveImage, onSetImage = _a.onSetImage;
    var _b = useState(''), name = _b[0], setName = _b[1];
    var onImageChange = function (event) {
        var files = event.target.files;
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function (e) {
            var base64Image = String(e.target.result);
            setName(files[0].name);
            onSetImage(files[0].name, base64Image);
        };
    };
    return (_jsxs(Box, { display: "flex", justifyContent: "space-between", children: [_jsxs(Stack, { direction: "row", children: [_jsxs(Button, { component: "label", children: ["Change image", _jsx("input", { type: "file", hidden: true, onChange: onImageChange })] }), _jsx(Typography, { padding: 1, children: name && name.substring(0, 36) })] }), _jsx(StyledRemoveImageButton, { children: _jsx(ClearIcon, { onClick: onRemoveImage, color: "error" }) })] }));
}
