import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { CRM_SYSTEM, getCrmSystem, mutateCrmSystems } from '../../../../common/crm-systems';
import { FormTextField } from '../../../../components/atoms/FormTextField';
export function EditCrmView(_a) {
    var crmSystems = _a.crmSystems, onChange = _a.onChange;
    var changeTenantService = function (crmName, crmUrl) {
        onChange(mutateCrmSystems(crmSystems, crmName, crmUrl));
    };
    var crmList = CRM_SYSTEM.map(function (name) { return ({
        name: name,
        crm: getCrmSystem({ crmSystems: crmSystems }, name)
    }); });
    return (_jsx(_Fragment, { children: crmList.map(function (c) {
            var _a;
            return (_jsx(FormTextField, { id: c.name, label: c.name, value: ((_a = c.crm) === null || _a === void 0 ? void 0 : _a.url) || '', onChange: function (value) { return changeTenantService(c.name, value); } }, c.name));
        }) }));
}
