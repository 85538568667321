import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
export var userManagerApi = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: getConfig().apiUserManager,
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token set when calling User API");
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    tagTypes: ['LookupFullname'],
    endpoints: function (builder) { return ({
        lookupUser: builder.query({
            query: function (id) { return "api/v1/admin/users/".concat(id, "/lookup"); },
            providesTags: function (result, error, id) { return [{ type: 'LookupFullname', id: id }]; }
        })
    }); }
});
export var useLookupUserQuery = userManagerApi.useLookupUserQuery;
