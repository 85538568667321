import { jsx as _jsx } from "react/jsx-runtime";
import RefreshIcon from '@mui/icons-material/Refresh';
import { Fab } from '@mui/material';
import React from 'react';
import { profileApi } from '../../../../../api/profileApi';
import { useAppDispatch } from '../../../../../redux/store';
export var RefreshButton = function () {
    var dispatch = useAppDispatch();
    var onRefresh = function () {
        dispatch(profileApi.util.invalidateTags(['Profiles']));
    };
    return (_jsx(Fab, { color: "primary", size: "small", onClick: onRefresh, children: _jsx(RefreshIcon, {}) }));
};
