import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useLookupUserQuery } from '../../../../../api/userManager';
import { StyledTenantDescription, StyledTenantDescriptionAttribute, StyledTenantDetailsInformation } from '../TenantDetailsSideArea.styles';
export default function LookupUser(_a) {
    var icon = _a.icon, value = _a.value, attribute = _a.attribute, dataTestId = _a.dataTestId;
    var _b = useLookupUserQuery(value), data = _b.data, isLoading = _b.isLoading;
    var valueOrNotFound = data ? data.fullName : 'Not Found';
    var description = data ? data.email : '';
    var name = isLoading ? 'Loading...' : valueOrNotFound;
    return (_jsxs(StyledTenantDetailsInformation, { title: description, children: [icon, _jsx(StyledTenantDescriptionAttribute, { noWrap: true, children: attribute }), _jsx(StyledTenantDescription, { "data-testid": dataTestId, noWrap: true, children: name })] }));
}
