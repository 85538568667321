import { jsx as _jsx } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { getConfig } from '../../../../../../common/config';
import NoImage from '../../../../../../components/errorHandling/NoImage';
var ImageWithLink = function (_a) {
    var tenantId = _a.tenantId, image = _a.image, _b = _a.height, height = _b === void 0 ? '50px' : _b, _c = _a.width, width = _c === void 0 ? '50px' : _c, style = _a.style, showText = _a.showText;
    if (!image || image.length > 36) {
        return (_jsx(Link, { to: "/tenants/".concat(tenantId, "/add-image"), style: { textAlign: 'center' }, title: "Click to Add Image", children: _jsx(NoImage, { height: height, width: width, showText: showText }) }));
    }
    return (_jsx(Link, { to: "/tenants/".concat(tenantId, "/edit-image"), style: { textAlign: 'center' }, title: "Click to Edit Image", children: _jsx("img", { alt: 'No pic available', src: "".concat(getConfig().apiUrlTenants, "/api/v1/images/").concat(image), height: height, width: width, style: style }) }));
};
export default ImageWithLink;
