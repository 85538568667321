import { createSlice } from '@reduxjs/toolkit';
export var ErrorLevel;
(function (ErrorLevel) {
    ErrorLevel[ErrorLevel["NONE"] = 0] = "NONE";
    ErrorLevel[ErrorLevel["ALERT"] = 1] = "ALERT";
    ErrorLevel[ErrorLevel["PAGE"] = 2] = "PAGE";
})(ErrorLevel || (ErrorLevel = {}));
var initialState = { errorLevel: ErrorLevel.NONE, message: '' };
export var errorSlice = createSlice({
    name: 'error',
    initialState: initialState,
    reducers: {
        addError: function (state, _a) {
            var payload = _a.payload;
            return payload;
        },
        clearError: function () { return initialState; }
    }
});
