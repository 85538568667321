var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useSaveTenantMutation } from '../../../../../../../api/tenantApi';
import { getPcsConfig, PCS_REGIONS, setPcsConfig } from '../../../../../../../common/pricer-services';
import CustomCollapse from '../../../../../../../components/atoms/CustomCollapse';
import { FormSelect } from '../../../../../../../components/atoms/FormSelect';
import { countries } from './CountryCodeHelper';
var makePcsName = function (pcsId) {
    return pcsId.toLowerCase().replaceAll(' ', '-');
};
var preSelectCountry;
function extractCountryCode(name) {
    var code = name.substring(name.lastIndexOf('-') + 1);
    var country = countries.find(function (c) { return c.code.toLowerCase() === code.toLowerCase(); });
    preSelectCountry = country || undefined;
    return country ? country.code.toLowerCase() : '';
}
export default function AddMetadataDialog(_a) {
    var _this = this;
    var tenant = _a.tenant, open = _a.open, onClose = _a.onClose;
    var _b = useState(makePcsName(tenant.name)), name = _b[0], setName = _b[1];
    var _c = useState('EU'), region = _c[0], setRegion = _c[1];
    var _d = React.useState(''), errorMessage = _d[0], setErrorMessage = _d[1];
    var saveTenant = useSaveTenantMutation()[0];
    var _e = useState(extractCountryCode(tenant.name)), countryCode = _e[0], setCountryCode = _e[1];
    var onSubmit = function () { return __awaiter(_this, void 0, void 0, function () {
        var newTenant, config, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    newTenant = __assign({}, tenant);
                    config = getPcsConfig(newTenant) || {};
                    if (!validPcsName(name)) {
                        setErrorMessage('Invalid PCS name! Only lower-case letters are allowed, it needs to end with a hyphen and country code, and have maximum 17 characters in total.');
                        return [2 /*return*/];
                    }
                    setPcsConfig(newTenant, __assign(__assign({}, config), { requestedPcsId: name, region: region }));
                    return [4 /*yield*/, saveTenant(newTenant).unwrap()];
                case 1:
                    _a.sent();
                    onClose();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.error('Failed to save: ', e_1);
                    setErrorMessage('Failed to save tenant');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var validPcsName = function (name) {
        if (name.length > 17) {
            return false;
        }
        if (!name.match('^[a-z0-9][a-z0-9-]*-[a-z]{2}$')) {
            return false;
        }
        return true;
    };
    return (_jsxs(Dialog, { open: open, onClose: onClose, children: [_jsx(DialogTitle, { children: "Request Central Manager" }), _jsx(CustomCollapse, { errorMessage: errorMessage, onClose: function () { return setErrorMessage(''); } }), _jsxs(DialogContent, { children: [_jsx(TextField, { value: name, autoFocus: true, size: 'medium', fullWidth: true, margin: "dense", id: "name", label: "Name", sx: { marginBottom: '20px', background: '#ffffff' }, variant: "outlined", onChange: function (event) {
                            if (event.target.value || event.target.value.length > 0) {
                                setErrorMessage('');
                            }
                            setName(event.target.value);
                        } }), _jsx(TextField, { value: name, size: 'medium', fullWidth: true, margin: "dense", label: "Requested name", sx: { marginBottom: '20px', background: '#ffffff' }, disabled: true }), _jsx(Autocomplete, { size: 'medium', options: countries, autoHighlight: true, getOptionLabel: function (option) { return option.code; }, defaultValue: preSelectCountry, renderOption: function (props, option) { return (_jsxs(Box, __assign({ component: "li", sx: { '& > img': { mr: 2, flexShrink: 0 } } }, props, { children: [_jsx("img", { loading: "lazy", width: "20", srcSet: "https://flagcdn.com/w40/".concat(option.code.toLowerCase(), ".png 2x"), src: "https://flagcdn.com/w20/".concat(option.code.toLowerCase(), ".png"), alt: "" }), option.label, " (", option.code, ")"] }))); }, onInputChange: function (_, value, reason) {
                            if (reason === 'selectOption') {
                                var newCountryCode = "".concat(value.toLowerCase());
                                if (countryCode) {
                                    setName(name.replace(new RegExp("-".concat(countryCode, "(?=[^]*$)")), "-".concat(newCountryCode)));
                                }
                                else {
                                    setName("".concat(name, "-").concat(newCountryCode));
                                }
                                setCountryCode(newCountryCode);
                            }
                            else if (reason === 'clear') {
                                setName(name.replace(countryCode ? new RegExp("-".concat(countryCode, "(?=[^]*$)")) : '', ''));
                                setCountryCode('');
                            }
                        }, renderInput: function (params) { return (_jsx(TextField, __assign({}, params, { label: "Choose a country", inputProps: __assign(__assign({}, params.inputProps), { autoComplete: 'new-password' // disable autocomplete and autofill
                             }) }))); } }), _jsx(FormSelect, { id: "region", label: "Region", onChange: function (value) { return setRegion(value); }, value: region, options: PCS_REGIONS })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: function () { return onClose(); }, children: "Cancel" }), _jsx(Button, { onClick: onSubmit, children: "Save" })] })] }));
}
