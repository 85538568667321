import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { getConfig } from '../../common/config';
import NoImage from '../errorHandling/NoImage';
export default function TenantImage(_a) {
    var image = _a.image, _b = _a.height, height = _b === void 0 ? '50px' : _b, _c = _a.width, width = _c === void 0 ? '50px' : _c, style = _a.style, showText = _a.showText;
    if (!image || image.length > 36) {
        return _jsx(NoImage, { height: height, width: width, showText: showText });
    }
    var imageSrc = "".concat(getConfig().apiUrlTenants, "/api/v1/images/").concat(image);
    return (_jsx("img", { alt: 'No pic available', src: imageSrc, height: height, width: width, style: style }));
}
