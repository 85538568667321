import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
export var NameDialog = function (_a) {
    var show = _a.show, inputGivenName = _a.givenName, inputFamilyName = _a.familyName, onSave = _a.onSave, onClose = _a.onClose;
    var _b = React.useState(inputGivenName), givenName = _b[0], setGivenName = _b[1];
    var _c = React.useState(inputFamilyName), familyName = _c[0], setFamilyName = _c[1];
    var handleSave = function () {
        onSave(givenName, familyName);
    };
    return (_jsxs(Dialog, { open: show, onClose: onClose, children: [_jsx(DialogTitle, { children: "Change name" }), _jsxs(DialogContent, { children: [_jsx(TextField, { autoFocus: true, margin: "dense", id: "givenName", label: "Given name", fullWidth: true, value: givenName, variant: "standard", onChange: function (e) { return setGivenName(e.target.value); } }), _jsx(TextField, { autoFocus: true, margin: "dense", id: "lastName", label: "Last Name", fullWidth: true, value: familyName, variant: "standard", onChange: function (e) { return setFamilyName(e.target.value); } })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: handleSave, children: "Update" })] })] }));
};
