import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
var NoAccessPage = function () {
    var logout = useAuth0().logout;
    // Give user the option to tenant switch (could be the case that user has a tenants with tenant with no pricer role and tenant with pricer-role)?
    // This could be done by logout and login again though
    return (_jsxs(Box, { sx: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            flexDirection: 'column'
        }, children: [_jsx(Typography, { variant: "h1", children: "No access to this site" }), _jsx(Typography, { variant: "h2", children: "Please contact the administrator" }), _jsx(Button, { onClick: function () {
                    return logout({ logoutParams: { returnTo: window.location.origin } });
                }, children: "Logout" })] }));
};
export default NoAccessPage;
