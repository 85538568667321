import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../../../common/hooks/authentication';
export default function NameAndBackButton(_a) {
    var id = _a.id, name = _a.name;
    var parentTenantId = useAuthentication().parentTenant;
    var navigate = useNavigate();
    return (_jsxs("div", { style: {
            display: 'flex',
            alignItems: 'center',
            padding: '15px'
        }, children: [parentTenantId !== id && (_jsx(ArrowBackIcon, { sx: { marginRight: '10px', cursor: 'pointer' }, onClick: function () { return navigate("/tenants/".concat(parentTenantId)); } })), _jsx(Typography, { "data-testid": "name-selected-tenant", fontWeight: 'bold', fontSize: '14px', children: name })] }));
}
