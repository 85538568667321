import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import AddLinkIcon from '@mui/icons-material/AddLink';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import EngineeringIcon from '@mui/icons-material/Engineering';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { Divider, Fab, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
export function EditMenu(_a) {
    var id = _a.id;
    var _b = useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var open = Boolean(anchorEl);
    var navigate = useNavigate();
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    return (_jsxs("div", { children: [_jsx(Fab, { color: "primary", "aria-controls": open ? 'basic-menu' : undefined, "aria-haspopup": "true", "aria-expanded": open ? 'true' : undefined, "data-testid": "admin-menu", size: "small", onClick: handleClick, children: _jsx(EngineeringIcon, {}) }), _jsxs(Menu, { id: "basic-menu", anchorEl: anchorEl, open: open, onClose: handleClose, MenuListProps: {
                    'aria-labelledby': 'basic-button'
                }, children: [_jsxs(MenuItem, { onClick: function () { return navigate("/tenants/".concat(id, "/edit")); }, children: [_jsx(ListItemIcon, { children: _jsx(EditIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Edit Tenant" })] }), _jsxs(MenuItem, { onClick: function () { return navigate("/tenants/".concat(id, "/edit-links")); }, children: [_jsx(ListItemIcon, { children: _jsx(InsertLinkIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Manage links" })] }), _jsx(Divider, {}), _jsxs(MenuItem, { onClick: function () { return navigate("/tenants/".concat(id, "/create")); }, children: [_jsx(ListItemIcon, { children: _jsx(AddLinkIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Add tenant" })] }), _jsxs(MenuItem, { onClick: function () { return navigate("/tenants/".concat(id, "/create/onprem")); }, children: [_jsx(ListItemIcon, { children: _jsx(AddLinkIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Add onprem tenant" })] }), _jsx(Divider, {}), _jsxs(MenuItem, { onClick: function () { return navigate("/tenants/".concat(id, "/delete")); }, children: [_jsx(ListItemIcon, { children: _jsx(DeleteForeverIcon, { fontSize: "small" }) }), _jsx(ListItemText, { children: "Delete this tenant" })] })] })] }));
}
