import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useGetTenantQuery } from '../../api/tenantApi';
import { useErrors } from '../../common/hooks/errors';
import { TenantSelectorPageWrapper } from '../../components/atoms/TenantSelectorPageWrapper';
import Loading from '../../components/molecules/Loading';
import { TenantSelectorView } from './components/TenantSelectorView';
import { TenantDetailsSideArea } from './components/TenantSideArea/TenantDetailsSideArea';
export default function TenantSelectorPage() {
    var id = useParams().id;
    var _a = useGetTenantQuery(id), tenant = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    var addAlert = useErrors().addAlert;
    useEffect(function () {
        if (isError) {
            addAlert('Failed to find tenant');
        }
    }, [addAlert, isError]);
    if (isLoading) {
        return _jsx(Loading, { title: 'Loading please wait' });
    }
    return (_jsx(TenantSelectorPageWrapper, { isLoading: isLoading, detail: _jsx(TenantDetailsSideArea, { tenant: tenant }), children: _jsx(TenantSelectorView, { tenant: tenant }) }));
}
