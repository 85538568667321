export function getCrmSystem(_a, name) {
    var tenant = _a.tenant, crmSystems = _a.crmSystems;
    if (tenant) {
        return getCrmSystem({ crmSystems: tenant.crmSystems }, name);
    }
    if (!crmSystems) {
        return undefined;
    }
    return crmSystems.find(function (c) { return c.name.toLowerCase() === name.toLowerCase(); });
}
export function mutateCrmSystems(services, name, url) {
    if (!services || services.length === 0) {
        return [{ name: name, url: url }];
    }
    var copy = services.filter(function (c) { return c.name.toLowerCase() !== name.toLowerCase(); });
    copy.push({ name: name.toLowerCase(), url: url });
    return copy;
}
export function removeInactiveCrmSystems(crmSystems) {
    if (!crmSystems || crmSystems.length === 0) {
        return [];
    }
    return crmSystems.filter(function (s) { return s.name && s.name.length > 0 && s.url && s.url.length > 0; });
}
export function setCrmSystem(tenant, name, url) {
    tenant.crmSystems = mutateCrmSystems(tenant.crmSystems, name, url);
}
export var SALESFORCE = 'Salesforce';
export var JEEVES = 'Jeeves';
export var OTHER_CRM = 'Other crm';
export var CRM_SYSTEM = [SALESFORCE, JEEVES, OTHER_CRM];
