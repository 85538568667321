import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getConfig } from '../common/config';
export var auth0UserApi = createApi({
    reducerPath: 'auth0User',
    baseQuery: fetchBaseQuery({
        baseUrl: "https://".concat(getConfig().auth0Domain),
        prepareHeaders: function (headers, _a) {
            var getState = _a.getState;
            var token = getState().auth.token;
            if (token) {
                headers.set('authorization', "Bearer ".concat(token));
            }
            else {
                console.error("No token set when calling Auth API");
            }
            headers.set('Content-Type', 'application/json');
            return headers;
        }
    }),
    endpoints: function (builder) { return ({
        lookupUser: builder.query({
            query: function () { return "userinfo"; }
        })
    }); }
});
export var useLookupUserQuery = auth0UserApi.useLookupUserQuery;
