import { styled } from '@mui/material/styles';
export var StyledTenantDetailsArea = styled('div')(function () { return ({
    minWidth: '400px',
    maxWidth: '400px',
    background: 'white',
    borderBottom: '1px solid #CECFCE',
    borderRight: '1px solid #CECFCE',
    borderLeft: '1px solid #CECFCE',
    height: 'calc(100vh - 65px)',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden'
}); });
export var StyledTenantAreaWrapper = styled('div')(function () { return ({
    width: '100%'
}); });
