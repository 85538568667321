var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import { getPricerService, METADATA_FIELDS, mutatePricerService } from '../../../../common/pricer-services';
import { FieldsView } from './FieldsView';
export function SingleServiceView(_a) {
    var services = _a.services, onChange = _a.onChange;
    var fields = Object.keys(METADATA_FIELDS)
        .map(function (name) {
        var _a;
        return ({
            name: name,
            configuration: ((_a = getPricerService({ services: services }, name)) === null || _a === void 0 ? void 0 : _a.configuration) || {},
            fields: METADATA_FIELDS[name]
        });
    })
        .filter(function (_a) {
        var fields = _a.fields;
        return fields.length > 0;
    });
    var update = function (type, key, value) {
        var _a;
        var existing = __assign({}, (((_a = getPricerService({ services: services }, type)) === null || _a === void 0 ? void 0 : _a.configuration) || {}));
        existing[key] = value;
        onChange(mutatePricerService(services, type, existing));
    };
    return (_jsx("div", { children: fields.map(function (_a) {
            var configuration = _a.configuration, name = _a.name, fields = _a.fields;
            return (_jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), id: name, children: _jsx(Typography, { children: name }) }), _jsx(AccordionDetails, { children: _jsx(FieldsView, { name: name, fields: fields, configuration: configuration, onChange: function (name, key, value) { return update(name, key, value); } }) })] }, name));
        }) }));
}
