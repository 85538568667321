var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import EditIcon from '@mui/icons-material/Edit';
import { Card, CardActions, CardContent, CardHeader, IconButton, Typography } from '@mui/material';
import React from 'react';
import { AvatarIcon } from '../atoms/AvatarIcon';
import { ChangePasswordButton } from '../atoms/ChangePasswordButton';
import { NameDialog } from './NameDialog';
import { TenantList } from './TenantList';
var getInitials = function (firstName, lastName, email) {
    var firstInitial = firstName.charAt(0);
    var lastInitial = lastName.charAt(0);
    if (firstInitial && lastInitial) {
        return "".concat(firstInitial).concat(lastInitial);
    }
    var emailName = email.split('@')[0].toUpperCase();
    if (emailName.includes('.')) {
        var _a = emailName.split('.'), first = _a[0], last = _a[1];
        if (first && last) {
            return "".concat(first.charAt(0)).concat(last.charAt(0));
        }
    }
    var emailInitial = email.charAt(0);
    if (emailInitial) {
        return emailInitial;
    }
    return '??';
};
var getName = function (firstName, lastName, email) {
    if (firstName && lastName) {
        return "".concat(firstName, " ").concat(lastName);
    }
    var emailName = email.split('@')[0];
    return emailName.replace('.', ' ');
};
export var ProfileCard = function (_a) {
    var profile = _a.profile, onSave = _a.onSave;
    var _b = React.useState(false), isEditing = _b[0], setIsEditing = _b[1];
    var initials = getInitials(profile.givenName, profile.familyName, profile.email);
    var name = getName(profile.givenName, profile.familyName, profile.email);
    var handleClose = function () { return setIsEditing(false); };
    var handleSave = function (givenName, familyName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, onSave(givenName, familyName)];
                case 1:
                    if (_a.sent()) {
                        setIsEditing(false);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(_Fragment, { children: [_jsxs(Card, { sx: { maxWidth: 345 }, children: [_jsx(CardHeader, { avatar: _jsx(AvatarIcon, { initials: initials, size: 75 }), title: name, subheader: profile.email, action: !profile.readOnly && (_jsx(IconButton, { "aria-label": "change name", onClick: function () { return setIsEditing(true); }, children: _jsx(EditIcon, {}) })) }), _jsxs(CardContent, { children: [_jsx(Typography, { variant: "h6", children: "Your tenants:" }), _jsx(TenantList, { profile: profile })] }), _jsx(CardActions, { children: !profile.readOnly && _jsx(ChangePasswordButton, {}) })] }), _jsx(NameDialog, { show: isEditing, onClose: handleClose, familyName: profile.familyName, givenName: profile.givenName, onSave: handleSave })] }));
};
