export var has = function (value) {
    return value != null && value.length > 0;
};
export var stringToColor = function (string) {
    if (!string)
        string = 'aaa';
    var hash = 0;
    var i;
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = '#';
    for (i = 0; i < 3; i += 1) {
        var value = (hash >> (i * 8)) & 0xff;
        color += "00".concat(value.toString(16)).slice(-2);
    }
    return color;
};
