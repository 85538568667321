import { jsx as _jsx } from "react/jsx-runtime";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { TenantSearchBarSingleRow } from './TenantSearchBar.styles';
export default function TenantSearchBarInputField(_a) {
    var handleClose = _a.handleClose, handleSearch = _a.handleSearch, searchWord = _a.searchWord;
    return (_jsx(TenantSearchBarSingleRow, { children: _jsx(TextField, { value: searchWord, fullWidth: true, margin: "dense", id: "searchField", sx: { background: '#ffffff' }, variant: "outlined", onChange: function (event) {
                handleSearch(event.target.value);
            }, InputProps: {
                sx: { height: '48px', width: '307px' },
                endAdornment: (_jsx(InputAdornment, { position: "end", children: _jsx(HighlightOffIcon, { onClick: function () {
                            handleClose();
                        }, sx: {
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        } }) })),
                startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(SearchIcon, { style: { color: 'black' } }) }))
            } }) }));
}
