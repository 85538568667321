var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControlLabel, Switch } from '@mui/material';
import React from 'react';
import { CONTRACT_STATUS, DEFAULT_ONPREM_LICENSE_TYPE, LICENSE_TYPES, TYPES } from '../../../../common/tenant.constans';
import { FormSelect } from '../../../../components/atoms/FormSelect';
import { FormTextField } from '../../../../components/atoms/FormTextField';
import { UploadImageButton } from '../../../../components/atoms/UploadImageButton';
export function EditDetailsView(_a) {
    var details = _a.details, onChange = _a.onChange;
    var isOnPrem = details.licenseType === DEFAULT_ONPREM_LICENSE_TYPE;
    return (_jsxs(_Fragment, { children: [_jsx(FormTextField, { id: "name", label: "Tenant Name", value: details.name, autoFocus: true, onChange: function (value) { return onChange(__assign(__assign({}, details), { name: value })); } }), _jsx(FormTextField, { id: "description", label: "Tenant Description", value: details.description, onChange: function (value) { return onChange(__assign(__assign({}, details), { description: value })); } }), _jsx(UploadImageButton, { onSetImage: function (name, data) { return onChange(__assign(__assign({}, details), { image: data })); }, onRemoveImage: function () { return onChange(__assign(__assign({}, details), { image: '' })); } }), _jsx(FormSelect, { id: "type", label: "Type", disabled: isOnPrem, onChange: function (value) { return onChange(__assign(__assign({}, details), { type: value })); }, value: details.type, options: TYPES }), _jsx(FormSelect, { id: "license", label: "License Type", disabled: isOnPrem, onChange: function (value) { return onChange(__assign(__assign({}, details), { licenseType: value })); }, value: details.licenseType, options: LICENSE_TYPES }), _jsx(FormSelect, { id: "contract-status", label: "Contract Status", disabled: isOnPrem, onChange: function (value) { return onChange(__assign(__assign({}, details), { contractStatus: value })); }, value: details.contractStatus, options: CONTRACT_STATUS }), _jsx(FormControlLabel, { control: _jsx(Switch, { color: "error", checked: details.targetForDecommission, onChange: function (event) {
                        return onChange(__assign(__assign({}, details), { targetForDecommission: event.target.checked }));
                    }, name: "Decommission" }), label: "Scheduled for decommission" })] }));
}
