import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useAuthentication } from '../../../common/hooks/authentication';
import { TenantNotFound } from './atoms/TenantNotFound';
import NoConnectedTenantsPage from './NoConnectedTenantsPage';
import TenantList from './TenantList';
import { StyledTenantArea } from './TenantSelectorView.styles';
import { TenantToolBar } from './TenantToolBar/TenantToolBar';
export function TenantSelectorView(_a) {
    var tenant = _a.tenant;
    var _b = useState(''), searchQuery = _b[0], setSearchQuery = _b[1];
    var _c = useState('All'), filterType = _c[0], setFilterType = _c[1];
    var _d = useState(false), checked = _d[0], setChecked = _d[1];
    var isPlazaUser = useAuthentication().isPlazaUser;
    var onSwitch = function () {
        setSearchQuery('');
        setChecked(false);
    };
    if (!tenant) {
        return _jsx(TenantNotFound, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(TenantToolBar, { tenant: tenant, searchWord: searchQuery, handleSearch: setSearchQuery, setFilterType: setFilterType, handleChecked: setChecked, checked: checked }), _jsx(StyledTenantArea, { children: isPlazaUser() ? (_jsx(NoConnectedTenantsPage, {})) : (_jsx(TenantList, { id: tenant.id, filterType: filterType, searchQuery: searchQuery, onSwitch: onSwitch })) })] }));
}
