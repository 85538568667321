import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { FormControl, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import React, { useState } from 'react';
export function EditableListItem2(_a) {
    var id = _a.id, label = _a.label, initialValue = _a.value, onChange = _a.onChange;
    var _b = useState(initialValue), value = _b[0], setValue = _b[1];
    var handleChange = function (value) {
        setValue(value);
        onChange(value);
    };
    return (_jsxs(FormControl, { variant: "standard", fullWidth: true, children: [_jsx(InputLabel, { htmlFor: id, children: label }), _jsx(Input, { id: id, value: value, onChange: function (e) { return handleChange(e.target.value); }, endAdornment: _jsx(InputAdornment, { position: "end", children: _jsx(IconButton, { onClick: function () { return handleChange(''); }, children: _jsx(HighlightOffIcon, {}) }) }) })] }));
}
