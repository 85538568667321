import { jsx as _jsx } from "react/jsx-runtime";
import { Alert, Box, Button, Paper } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../../common/hooks/authentication';
export function TenantNotFound() {
    var parentTenant = useAuthentication().parentTenant;
    var navigate = useNavigate();
    return (_jsx(Box, { padding: 2, children: _jsx(Paper, { children: _jsx(Alert, { severity: 'error', action: _jsx(Button, { color: "inherit", size: "small", onClick: function () { return navigate("/tenants/".concat(parentTenant)); }, children: "Return home" }), children: "Tenant not found..." }) }) }));
}
