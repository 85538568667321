import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ListItemText } from '@mui/material';
export var getRoles = function () {
    return {
        pricer: [
            {
                label: 'Admin',
                value: 'pricer-admin'
            },
            {
                label: 'Editor',
                value: 'pricer-editor'
            },
            {
                label: 'Viewer',
                value: 'pricer-viewer'
            }
        ],
        'professional-tenant': [
            {
                label: 'Tenant Admin',
                value: 'tenant-admin'
            },
            {
                label: 'Business Admin',
                value: 'business-admin'
            },
            {
                label: 'Status & Metrics',
                value: 'metrics'
            }
        ],
        'professional-tag': [
            {
                label: 'Store Manager',
                value: 'store-manager'
            },
            {
                label: 'Store Worker',
                value: 'store-worker'
            },
            {
                label: 'Installer',
                value: 'installer'
            },
            {
                label: 'Status & Metrics',
                value: 'metrics'
            },
            {
                label: 'Designer',
                value: 'designer'
            }
        ]
    };
};
export default function ListItemTextRoleLabel(_a) {
    var role = _a.role, currentRole = _a.currentRole;
    if (currentRole === role.value) {
        return (_jsxs(ListItemText, { children: [role.label, _jsx("strong", { children: _jsx("em", { children: " ( Current )" }) })] }));
    }
    else {
        return _jsx(ListItemText, { children: role.label });
    }
}
