import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SearchIcon from '@mui/icons-material/Search';
import { Collapse } from '@mui/material';
import React from 'react';
import TenantSearchBarInputField from './TenantSearchBarInputField';
export var TenantSearchBar = function (props) {
    var handleClose = function () {
        props.handleSearch('');
        props.handleChecked(false);
    };
    var internalHandleChecked = function (val) {
        props.handleChecked(val);
        if (val) {
            setTimeout(function () {
                var _a;
                (_a = document.getElementById('searchField')) === null || _a === void 0 ? void 0 : _a.focus();
            }, 120);
        }
    };
    return (_jsxs("div", { style: { display: 'flex', marginLeft: '11px' }, children: [_jsx("div", { style: { display: 'flex', alignItems: 'center' }, children: _jsx(SearchIcon, { "data-testid": "searchButton", onClick: function () { return internalHandleChecked(!props.checked); }, sx: {
                        display: props.checked ? 'none' : 'inline-block',
                        color: 'black',
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    } }) }), _jsx(Collapse, { timeout: 12, orientation: "horizontal", in: props.checked, children: _jsx(TenantSearchBarInputField, { searchWord: props.searchWord, handleSearch: props.handleSearch, handleClose: handleClose }) })] }));
};
